import { Fragment, useState, useEffect } from 'react'
import { motion } from "framer-motion"
import { Dialog, Transition } from '@headlessui/react'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, addDoc, collection, serverTimestamp } from "firebase/firestore";

import classNames from "classnames"

const firebaseConfig = {
  apiKey: "AIzaSyAj16lIVj_Igb2AOO_MUhLJWsLqCDkuB3U",
  authDomain: "chovain-6fb05.firebaseapp.com",
  databaseURL: "https://chovain-6fb05.firebaseio.com",
  projectId: "chovain-6fb05",
  storageBucket: "chovain-6fb05.appspot.com",
  messagingSenderId: "350850689440",
  appId: "1:350850689440:web:b1646aae96509ac9b89cc5",
  measurementId: "G-9VP01NDSXJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

const StoreIcon = ({ type, extraStyle, onClick }) => {
  const icons = {
    darkIos: ((extra) => (
      <button
        onClick={onClick}
        className={classNames("flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center", extra)}
      >
        <div className="mr-3">
          <svg viewBox="0 0 384 512" width="30" >
            <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
          </svg>
        </div>
        <div>
          <div className="text-xs">Download on the</div>
          <div className="text-2xl font-semibold font-sans -mt-1">App Store</div>
        </div>
      </button>
    )),
    lightIos: ((extra) => (
      <button
        onClick={onClick}
        className={classNames("flex mt-3 w-48 h-14 bg-transparent text-black border border-black rounded-xl items-center justify-center", extra)}
      >
        <div className="mr-3">
          <svg viewBox="0 0 384 512" width="30" >
            <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
          </svg>
        </div>
        <div>
          <div className="text-xs">Download on the</div>
          <div className="text-2xl font-semibold font-sans -mt-1">App Store</div>
        </div>
      </button>
    )),
    playStore: ((extra) => (
      <button
        onClick={onClick}
        className={classNames("flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center", extra)}
      >
        <div className="mr-3">
          <svg viewBox="30 336.7 120.9 129.2" width="30">
            <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"/>
            <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"/>
            <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"/>
            <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"/>
          </svg>
        </div>
        <div>
          <div className="text-xs">GET IT ON</div>
          <div className="text-xl font-semibold font-sans -mt-1">Google Play</div>
        </div>
      </button>
    ))
  };

  return icons[type](extraStyle);
}

const Form = ({ onSubmit, submitted, setEmail }) => (
  <div>
    <div className="mx-auto w-full">
      <div className="relative isolate">
        <h2 className="mx-auto w-full text-center text-2xl font-bold tracking-tight text-white">
          Get notified when the Android app gets live.
        </h2>
        <p className="mx-auto mt-2 w-full text-center text-md leading-6 text-neutral-300">
          The Android app is already on its way to the <strong>Google Playstore</strong>. Leave us your email and we will notify you as soon as the app gets live.
        </p>
        <form className="mx-auto mt-4 flex flex-col w-full gap-x-4" onSubmit={onSubmit}>
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          {
            submitted ?
              (
                <div className="self-center text-white font-medium text-md">Email submitted!</div>
              ) : (
                <>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="min-w-0 mb-2 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6 placeholder:text-white/70"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="flex-none rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-neutral-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Notify me
                  </button>
                </>
              )
          }
        </form>
      </div>
    </div>
  </div>
);

const Modal = ({ open, setOpen, onSubmit, submitted, setEmail }) => {
  // const [open, setOpen] = useState(true)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#725649] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <Form onSubmit={onSubmit} submitted={submitted} setEmail={setEmail}/>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default function App() {
  const [userAgent, setUserAgent] = useState();
  const [modalOpen, setModalOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState(null);

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    await addDoc(collection(db, 'android_newsletter'), { email, userAgent, createdAt: serverTimestamp() });
    logEvent(analytics, 'subscribe_androind_newsletter', {
      user_agent: userAgent,
    });
  };

  useEffect(() => {
    setUserAgent(getMobileOperatingSystem());
  }, []);

  useEffect(() => {
    if (userAgent) {
      logEvent(analytics, 'visit_download_page', {
        user_agent: userAgent,
      });
    }
  }, [userAgent]);

  return (
    <>
      <Modal open={modalOpen} setOpen={setModalOpen} onSubmit={submitHandler} submitted={submitted} setEmail={setEmail} />
      <div className="w-[100vh] h-full fixed rotate-[60deg] right-[100%] top-[-50%] rounded-[50px] bg-[#e1dddb]"/>
      <div className="w-[100vh] h-full fixed rotate-[60deg] left-[75%] top-[5%] rounded-[50px] bg-[#d8bdb1]"/>
      <div className="flex flex-col h-full relative z-20">
        <div className="flex justify-center items-center pt-4">
            <img src="/CHOVAIN_Logo_Black.png" className="w-[180px] h-auto" alt="CHOVAIN" />
        </div>
        <div className="flex justify-center grow items-center py-4">
          <div className="h-auto w-auto">
            <motion.img
              src="/iphone.png"
              className="h-auto w-[250px] object-cover"
              // initial={{ translateY: -5 }}
              animate={{ translateY: [-5, 5, 5, -5, -5] }}
              transition={{ duration: 15, repeat: Infinity }}

            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-start p-4 md:p-16 max-w-[960px] w-full self-center">
          <h1 className="text-3xl text-neutral-900 font-medium md:text-center w-full md:mb-2">Download our app!</h1>
          <p className="text-neutral-900 font-base mt-2 text-md md:text-center md:mb-2 md:font-medium">
            <strong>CHOVAIN</strong> is your on-demand beauty ally in Canada and France, where perfection is just a booking away, within your reach.
          </p>
          <div className="flex w-full flex-col sm:flex-row items-center mb-2">
            { (userAgent === 'iOS' || userAgent === 'unknown') && (
              <StoreIcon
                extraStyle="mr-1 w-full"
                type="darkIos"
                onClick={() => {
                  logEvent(analytics, 'click_ios_button', {
                    user_agent: userAgent,
                  });

                  window.open('https://apps.apple.com/ca/app/chovain/id1546952414', '_blank');
                }}
              />
            ) }
            { (userAgent === 'Android' || userAgent === 'unknown') && (
              <StoreIcon
                extraStyle="w-full"
                type="playStore"
                onClick={() => {
                  setModalOpen(true);
                }}
              />
            ) }
          </div>
          <button
            className="w-full flex align-center justify-center border py-2 rounded-md border-neutral-900"
            onClick={() => {
              logEvent(analytics, 'click_pro_button', {
                user_agent: userAgent,
              });

              if (userAgent === 'Android') {
                setModalOpen(true);
              } else {
                window.open('https://apps.apple.com/ca/app/chovain-pro/id1597516512', '_blank');
              }
            }}
          >
            I am a professional
          </button>
        </div>
      </div>
    </>
  )
}
